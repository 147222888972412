import React, {Component} from "react"
import Magento_to_shopify from "../images/magento-to-shopify-migration-service-banner.jpg"
import Hand_shopify from "../images/hand_shopify.jpg"
import M1_to_shopify from "../images/m1-to-shopify-migration-service.jpg"
import Mg_Arrow from "../images/mg__arrow.png"
import Migartion_Shopify from "../images/migrating-to-shopify.jpg"
import Layout from "../components/layout";
import Clutch from "../components/clutch";
import {Helmet} from "react-helmet";
import { Link } from "gatsby"


export default class Container extends Component {
  constructor(props) {
    super(props);
    this.state={
      url: ''
    }
  }
  componentDidMount() {
    this.setState({
      url: document.location.href
    });
  }
  render() {
    return (
        <Layout>
          <Helmet>
            <title>Magento 1 to Shopify Migration | Magento to Shopify Migration Services | CueForGood</title>
            <meta name="description"
                  content="Support for Magento 1.x ends June 2020. Shopify is an excellent alternative if you're thinking of migrating. Check out why you should choose Shopify as your next Ecommerce."/>
            <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta charset="utf-8"/>
            <link rel="canonical" href={this.state.url}/>

            <meta property="og:site_name" content="CueForGood"/>
            <meta property="fb:app_id" content="289086684439915"/>
            <meta property="og:url" content={this.state.url}/>
            <meta property="og:title"
                  content="Magento 1 to Shopify Migration | Magento to Shopify Migration Services | CueForGood"/>
            <meta property="og:description"
                  content="Support for Magento 1.x ends June 2020. Shopify is an excellent alternative if you're thinking of migrating. Check out why you should choose Shopify as your next Ecommerce."/>
            <meta property="og:image" content="https://www.CueForGood.com/images/m1-shopify"/>

            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:description"
                  content="Support for Magento 1.x ends June 2020. Shopify is an excellent alternative if you're thinking of migrating. Check out why you should choose Shopify as your next Ecommerce."/>
            <meta name="twitter:title"
                  content="Magento 1 to Shopify Migration | Magento to Shopify Migration Services | CueForGood"/>
            <meta name="twitter:site" content="@CueForGood"/>
            <meta name="twitter:image" content="https://www.cueforgood.com/images/m1-shopify"/>
          </Helmet>
          <section className="ser_sec_row   innerpages_space  shopify__migration--page">
            <div className="m2_migration ">
              <div className="container">
                <div className="mg__hero--cont">
                  <h1 className="migration__title heading_main">
                    <span>Migration from Magento 1</span>
                    <span>to Shopify</span>
                  </h1>
                  <div className="mg__hero--banner shopify__migration">
                    <img
                        src={Magento_to_shopify}
                        alt="Magento 1 to Shopify Migration Service"
                        className="img-adjust"
                    />
                  </div>
                </div>

                <div className="mg__why--section display-block">
                  <h2 className="heading_main">
                    Why do you need to move away from Magento 1?
                  </h2>
                  <ul>
                    <li>
                      As you may have heard, Magento has announced the ‘End of Life’
                      for its Magento1.x version with effect from June 2020. This
                      means no new features, no official support, and no security
                      updates/patches will be rolled out for Magento 1.x users around
                      the globe from June 2020 onwards.
                    </li>
                    <li>
                      The 'End of Life' countdown for Magento 1.x has officially
                      begun. Now, you have two options; Either stick to the Magento
                      ecosystem by upgrading from Magento 1 to Magento 2 or
                      re-platform/ migrate to another eCommerce platform like Shopify.
                    </li>
                  </ul>
                </div>

                <div className="mg__can--section display-block sh__can--section">
                  <h2 className="heading_main">
                    Shopify as an alternative to Magento
                  </h2>
                  <div className="mg__can--bottom display-block">
                    <p>
                      If you have decided to move away from Magento and have chosen
                      Shopify, you have made an excellent decision! Shopify is one of
                      the top-rated 'hosted platforms' in the world. Many Magento
                      stores have migrated/re-platformed from Magento 1 (Community as
                      well as Enterprise) to Shopify/Shopify Plus, opting against the
                      Magento 2 route for various reasons.
                      <br/>
                      <br/>
                      <strong>
                        Here are some reasons why some retailers favor Shopify:
                      </strong>
                      <br/>
                      <br/>
                      Shopify is quick to set up and easy to use. Shopify, being a
                      hosted solution, maintains the server and upgrades your website
                      at regular intervals.
                      <br/>
                      <br/>
                      24x7 customer support, community forums, Shopify University
                      guides, and the help center are all at your disposal anytime you
                      need help with any of Shopify’s features.
                    </p>
                    <p className="mg__float--right">
                      While the basic version of Shopify covers most of the useful
                      e-commerce functionalities, you can always find a suitable app
                      for all other features on the marketplace.
                      <br/>
                      <img
                          src={Hand_shopify}
                          alt="Why choose Shopify"
                          className="sh__hand--pic"
                      />
                      <span className="sh__right--links-con">
                  Check out our in-depth blog -<br/>
                      <a href="https://cueforgood.com/blog/why-shopify-ecommerce-platform/"
                        target="_blank" id="first-focus-element" className="focus-visible">
                        'To Shopify or Not to Shopify'
                        <span className="sr-only">(Opens in a new tab)</span>
                      </a>{" "}
                        which <br/>
                  lists 22 reasons why Shopify is a great choice
                </span>
                    </p>
                  </div>
                </div>
                <div className="sp__mg---section-how display-block">
                  <h2 className="heading_main">
                    How to start the Magento 1 to Shopify Migration Plan?
                  </h2>
                  <div className="mg__time--left mg-full">
                    <p>
                      Assuming that you have decided in favor of moving to Shopify /
                      Shopify Plus, you may still have some questions before you give
                      the go-ahead for the migration to Shopify.
                    </p>
                    <br/>
                    <br/>
                    <p>
                      <strong>
                        Some of the most common queries we receive include:
                      </strong>
                    </p>
                    <br/>
                    <br/>
                    <p>
                      <strong>Functionality:</strong> Will I lose any functionality or
                      features once my site migrates to Shopify? What will be
                      different with Shopify in comparison to what I have with Magento
                      at the moment?
                    </p>
                    <br/>
                    <p>
                      <strong>Scalability:</strong> Can Shopify handle my requirements
                      as I scale my business?
                    </p>
                    <br/>
                    <p>
                      <strong>Time:</strong> How long is the migration process from
                      Magento 1 to Shopify?
                    </p>
                    <br/>
                    <p>
                      <strong>Money:</strong> How much will it cost?
                    </p>
                    <br/>
                    <p>
                      <strong>Traffic Loss:</strong> What will be the impact on
                      traffic for the initial few weeks/months? How can I minimize
                      traffic loss post-migration?
                    </p>
                  </div>
                  <div className="mg__time--right mg-full">
                    <img
                        src={M1_to_shopify}
                        alt="How to migrate Magento 1 to Shopify"
                        className="img-adjust"
                    />
                  </div>
                </div>
                <div className="mg__sp--section sh__mg--help display-block">
                  <h2 className="heading_main">
              <span>
                <b>CueForGood</b> will help you!
              </span>
                  </h2>
                  <p>
                    We will help you to get answer to all of these (and more) Shopify
                    migration questions so you can make an informed decision.
                  </p>
                  <div className="mg__contact--button">
                    <Link to="/contact/" className="btn main_cta focus-visible">contact us</Link>
                  </div>
                </div>

                <div className="sh__mg--steps--cont">
                  <div className="mg__time--left">
                    <p>
                      Here is an <span>overview of the steps involved </span> in
                      ascertaining the project scope and preparing an estimate.{" "}
                    </p>
                    <ul className="mg__time--list">
                      <li>
                  <span>
                    <img
                        src={Mg_Arrow}
                        alt="Magento Migration"
                        className="img-adjust"
                    />
                  </span>
                        <p>
                          Firstly, we will evaluate your existing Magento setup to
                          understand what you are currently doing.
                        </p>
                      </li>
                      <li>
                  <span>
                    <img
                        src={Mg_Arrow}
                        alt="Magento Migration"
                        className="img-adjust"
                    />
                  </span>
                        <p>
                          Mapping: Existing functionality, extensions, integrations,
                          and customization will be documented and then mapped to what
                          is possible in Shopify.
                        </p>
                      </li>
                      <li>
                  <span>
                    <img
                        src={Mg_Arrow}
                        alt="Magento Migration"
                        className="img-adjust"
                    />
                  </span>
                        <p>
                          We will also highlight everything that will change with the
                          move from Magento to Shopify.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="mg__time--right">
                    <ul className="mg__time--list">
                      <li>
                  <span>
                    <img
                        src={Mg_Arrow}
                        alt="Magento Migration"
                        className="img-adjust"
                    />
                  </span>
                        <p>
                          Our team will iterate with you on the above steps so we can
                          lock this very important scope in the finalization process.
                        </p>
                      </li>
                      <li>
                  <span>
                    <img
                        src={Mg_Arrow}
                        alt="Magento Migration"
                        className="img-adjust"
                    />
                  </span>
                        <p>
                          Once the desired functionality is documented, our Shopify
                          experts will spend time to evaluate this and get back to you
                          with a total estimate (total time, cost, third party costs,
                          etc.)
                        </p>
                      </li>
                      <li className="sh__mg--steps--last">
                        <p>
                          And finally, when you give the go-ahead, we will get started
                          and make the migration from Magento to Shopify a seamless
                          process for you
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="mg__time--section  sh__time--section display-block">
                  <h2 className="heading_main">
                    Why CueForGood for Shopify Migration?
                  </h2>
                  <div className="mg__time--bottom sh__time--bottom display-block">
                    <div className="mg__time--left ">
                      <p>
                        {" "}
                        Our team understands both Magento and Shopify really well.
                      </p>
                      <ul className="mg__time--list">
                        <li>
                    <span>
                      <img
                          src={Mg_Arrow}
                          alt="Magento Migration"
                          className="img-adjust"
                      />
                    </span>
                          <p>
                            <b>Experience</b>
                            We have migrated several small to medium-sized stores from
                            Magento 1 to Shopify/ Shopify Plus, who have decided to
                            move away from Magento.
                          </p>
                        </li>
                        <li>
                    <span>
                      <img
                          src={Mg_Arrow}
                          alt="Magento Migration"
                          className="img-adjust"
                      />
                    </span>
                          <p>
                            <b>Support and Maintenance for any scale</b>
                            We provide ongoing maintenance, enhancement, and
                            customization services as your business grows. We don't
                            have a retainer model, so you only pay for the amount of
                            work required each month. It could be as less as 10 man
                            hours and go up to hundreds of hours each month.
                          </p>
                        </li>
                        <li>
                    <span>
                      <img
                          src={Mg_Arrow}
                          alt="Magento Migration"
                          className="img-adjust"
                      />
                    </span>
                          <p>
                            <b>SEO handling while migrating -</b>
                            Any re-platforming process can be very complex. It may
                            also lead to a massive drop in overall traffic if it is
                            not done by an adept team. Since 2005, our SEO team has
                            managed several platform migration projects. We bring this
                            vast SEO experience and knowhow of eCommerce platforms to
                            the front, for every project.
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div className="mg__time--right ">
                <span>
                  <img
                      src={Migartion_Shopify}
                      alt="Shopify Migration Service - CueForGood"
                  />
                </span>
                      <h2 className="mt_20">
                        Ready to get the Magento to Shopify migration planning started
                        with CueForGood?
                      </h2>
                      <h3>
                        Share the details of your requirements and our team will get
                        back to you as soon as possible.
                      </h3>
                      <div className="mg__contact--button">
                        <Link to="/contact/" className="btn main_cta focus-visible">contact us</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Clutch/>
        </Layout>
    )
  }
}